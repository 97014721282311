import axios from 'axios'
import { eventHub } from '../eventHub';

const http = axios.create ({
  baseURL: process.env.VUE_APP_API_URL
});

let activeHttpRequests = 0

http.interceptors.request.use(function (config) {
    if (activeHttpRequests === 0) {
      eventHub.$emit('loading-start')
    }

    activeHttpRequests++;

    // JWT
    const token = sessionStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config;
  }, function (error) {
    //console.info(error, activeHttpRequests)
    return Promise.reject(error);
  });

http.interceptors.response.use(function (response) {
    activeHttpRequests--;

    if (activeHttpRequests === 0) {
      eventHub.$emit('loading-finish')
    }

    return response;
  }, function (error) {
    activeHttpRequests--;

    if (error.response && error.response.status === 401) {
      eventHub.$emit('token-invalid', 'La sesión ya no es válida')
    }

    if (activeHttpRequests === 0) {
      eventHub.$emit('loading-finish')
    }

    return Promise.reject(error);
  });

export default http;
