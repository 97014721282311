import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'splash',
    meta: { layout: 'simple' },
    component: () => import('../views/SplashScreen.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: { layout: 'app' },
    redirect: () => {
      return { name: 'turnos' }
    }
  },
  {
    path: '/perfil',
    name: 'perfil',
    meta: {
      layout: 'app',
      title: 'Editar Cuenta'
    },
    component: () => import(/* webpackChunkName: "perfil" */ '../views/Perfil.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "forgot" */ '../views/Forgot.vue')
  },
  {
    path: '/registro',
    name: 'registro',
    meta: { layout: 'simple' },
    component: () => import('../views/Registro.vue')
  },
  {
    path: '/password/reset/:token',
    name: 'password_reset',
    meta: { layout: 'simple' },
    component: () => import('../views/PasswordReset.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'simple' },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/turnos',
    name: 'turnos',
    meta: {
      layout: 'app',
      title: 'Sacar Turnos'
    },
    component: () => import('../views/Turnos.vue')
  },
  {
    path: '/turnos/cancelar',
    name: 'turnos_cancelar',
    meta: {
      layout: 'app',
      title: 'Cancelar Turnos'
    },
    component: () => import('../views/TurnosCancelar.vue')
  },
  {
    path: '/datos-personales',
    name: 'datos-personales',
    meta: { layout: 'app' },
    component: () => import('../views/DatosPersonales.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Controlar que exista la configuración
  if ((to?.name !== 'splash') && (localStorage.getItem('ajustes') === null)) {
    return next('/');
  }

  // Controlar que el usuario tenga un token
  const publicPages = ['splash', 'login', 'forgot', 'password_reset', 'registro']
  const authRequired = !publicPages.includes(to?.name || '')
  const loggedIn = sessionStorage.getItem('token') // Es válido el token?

  if (authRequired && !loggedIn) {
    if (to.name !== 'login') {
      return next('/login');
    }
  }

  next()
})

export default router
