<template>
  <v-app>
    <component v-bind:is="layout"></component>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="!!snackbarColor ? undefined : 'pink'"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppLayout from './layouts/AppLayout'
import SimpleLayout from './layouts/SimpleLayout'

export default {
  name: 'App',
  data: () => ({
    snackbar: false,
    snackbarText: '',
    snackbarColor: '',
    invalidToken: false,
    invalidTokenReason: ''
  }),
  computed: {
    isLoggedIn () {
      return this.$store.state.loggedIn
    },
    layout () {
      return (this.$route.meta.layout || 'simple') + '-layout'
    }
  },
  created: function () {
    this.$eventHub.$on('token-invalid', (message) => this.revokeToken(message))
    this.$eventHub.$on('loading-finish', () => this.stopLoading())
    this.$eventHub.$on('snackbar-message', (message, color) => this.showSnackbar(message, color))
    this.$eventHub.$on('http-error', (error) => {
      this.showSnackbar(error?.response?.data?.message || 'No se pudo procesar su solicitud', 'error')
    })
  },
  beforeDestroy: function () {
    this.$eventHub.$off('token-invalid')
    this.$eventHub.$off('loading-finish')
    this.$eventHub.$off('snackbar-message')
    this.$eventHub.$off('http-error')
  },
  methods: {
    logout: function () {
      sessionStorage.removeItem('token')
      this.$store.state.loggedIn = false

      if (this.$route.name === 'login') {
        // ignorar resto del código si ya se está en la pantalla de login
        return
      }

      this.$router.push({name: 'login'})
    },
    stopLoading: function () {
      if (this.invalidToken) {
        this.invalidToken = false
        this.showSnackbar(this.invalidTokenReason, 'error')
        this.invalidTokenReason = ''
        this.logout()
      }
    },
    revokeToken: function (message) {
      message = (typeof message === 'undefined') ? 'Error de conexión' : message

      this.invalidToken = true
      this.invalidTokenReason = message
      this.stopLoading()
    },
    showSnackbar: function (message, color) {
      this.snackbarText = message
      this.snackbarColor = color
      this.snackbar = true
    },
    errorHandler: (error) => {
      switch (error.response.status) {
        case 401:
          break;
        default:
          this.showSnackbar('Disculpe, ocurrío un error al procesar su solicitud', 'error')
      }
    },
  },
  components: {
    'app-layout': AppLayout,
    'simple-layout': SimpleLayout
  }
};
</script>
