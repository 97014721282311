import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import http from './plugins/http'
import { eventHub } from './eventHub';
import './plugins/vee-validate';
import './main.css'

Vue.config.productionTip = false
Vue.config.silent = true

Vue.prototype.$http = http
Vue.prototype.$eventHub = eventHub

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate: function () {
    this.$store.state.loggedIn = !!sessionStorage.getItem('token')
    this.$store.state.paciente = parseInt(sessionStorage.getItem('paciente'))
    this.$store.state.ajustes = JSON.parse(localStorage.getItem('ajustes'))
  }
}).$mount('#app')
