<template>
  <v-app>
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          link
          exact
          :to="{name: 'turnos'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-clock-plus-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Sacar Turnos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          exact
          :to="{name: 'turnos_cancelar'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-clock-remove-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Cancelar Turnos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'datos-personales'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-card-account-details-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Datos Personales</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="caption pa-2 body-2">
          JQTurnos &copy; {{ new Date().getFullYear() }}<br>
          Desarrollado por <a href="https://jaque.com.ar" target="_blank" rel="nofollow" class="font-weight-bold text-decoration-none">JAQUE Software</a>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      color="indigo"
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ this.$route.meta.title || 'JQ Turnos' }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon to="/perfil">
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-btn>

      <v-btn icon @click="logout()">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      drawer: null
    }),
    methods: {
      logout: function () {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('paciente')
        this.$store.state.loggedIn = false
        this.$store.state.paciente = null
        this.$router.push({name: 'login'}, () => {})
      },
    },
  }
</script>
